import Checkbox from "@brighthr/component-checkbox";
import { ValueCallback } from "shared/core/types/callbacks";
import { useEffect, useState } from "react";
import "./CheckboxGrid.css";

export type CheckboxGridItem = {
  id: string;
  key: string;
  displayText: string;
  selected: boolean;
};

export type CheckboxGridDependency = {
  key: string;
  requiredMessage: string;
  dependentKeys: string[];
};

export type CheckboxGridProps = {
  headingText: string;
  items: CheckboxGridItem[];
  dependencies?: CheckboxGridDependency[];
  disabled?: boolean;
  onChange: ValueCallback<CheckboxGridItem[]>;
  radioFields?: string[];
};

export const CheckboxGrid = ({ headingText, items, dependencies = [], onChange, disabled = false, radioFields = [] }: CheckboxGridProps) => {
  const [itemsInternal, setItemsInternal] = useState(items);

  const isItemDisabled = (key: string): boolean => {
    const [parentSelected] = isParentSelected(key);
    return !parentSelected;
  };

  const getRequiredMessage = (key: string): string | undefined => {
    const [parentSelected, parentDependency] = isParentSelected(key);
    if (!parentSelected) {
      return parentDependency?.requiredMessage;
    }
    return undefined;
  };

  const isParentSelected = (key: string): [boolean, CheckboxGridDependency | undefined] => {
    const isParent = dependencies.find((d) => d.key === key) !== undefined;
    if (isParent) {
      return [true, undefined];
    }

    const parentDependency = dependencies.find((d) => d.dependentKeys.includes(key));
    if (!parentDependency) {
      return [true, undefined];
    }

    const parentItemIndex = itemsInternal.findIndex((item) => item.key === parentDependency.key);
    if (parentItemIndex === -1) {
      throw new Error(`Could not find parent checkbox item with key '${parentDependency.key}'`);
    }

    return [itemsInternal[parentItemIndex].selected, parentDependency];
  };

  const handleRadioSelection = (key: string, selected: boolean, state: CheckboxGridItem[]) => {
    if (radioFields.includes(key) && selected) {
      const otherRadioFields = radioFields.filter((field) => field !== key);
      otherRadioFields.forEach((field) => {
        const fieldIndex = state.findIndex((item) => item.key === field);
        if (fieldIndex === -1) {
          throw new Error(`Could not find other radio fields`);
        }
        state[fieldIndex].selected = false;
      });
    }
  };

  const updateItemSelected = (key: string, index: number, selected: boolean) => {
    const updatedState = [...itemsInternal];
    if (radioFields.includes(key) && !selected) {
      updatedState[index].selected = true;
    } else {
      updatedState[index].selected = selected;
    }
    deselectDependentItems(key, selected, updatedState);
    handleRadioSelection(key, selected, updatedState);
    onChange(updatedState);
  };

  const deselectDependentItems = (key: string, selected: boolean, state: CheckboxGridItem[]) => {
    const parentDependency = dependencies.find((d) => d.key === key);
    if (parentDependency && !selected) {
      parentDependency.dependentKeys.forEach((dependentKey) => {
        const dependentItemIndex = state.findIndex((item) => item.key === dependentKey);
        if (dependentItemIndex === -1) {
          throw new Error(`Could not find dependent checkbox item with key '${dependentKey}'`);
        }
        state[dependentItemIndex].selected = false;
      });
    }
  };

  useEffect(() => {
    setItemsInternal(items);
  }, [items]);

  return (
    <div className="mt-2">
      <h4 className={disabled ? "text-neutral-500" : ""}>{headingText}</h4>
      <div className="flex flex-wrap">
        {itemsInternal?.map((item, index) => {
          return (
            <div className="basis-1/3 mt-4" key={item.id}>
              <Checkbox
                checked={item.selected}
                disabled={disabled || isItemDisabled(item.key)}
                onChange={(e) => updateItemSelected(item.key, index, e.target.checked)}
                label={item.displayText}
                description={getRequiredMessage(item.key)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
