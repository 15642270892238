import { AdminUserState } from "core/state/adminUserState";
import { AdminDomainLocaleCode } from "shared/core/locale";

export const useGetAdminLocale = (hostname: string): string => {
  let locale = "en";
  const adminUser = AdminUserState.get();
  const domain = Object.keys(AdminDomainLocaleCode).find((uri: string) => uri.includes(hostname));

  if (adminUser && adminUser.client?.localeCode) {
    locale = adminUser.client?.localeCode;
  } else if (domain) {
    locale = AdminDomainLocaleCode[domain];
  }

  return locale;
};
