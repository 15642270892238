import { ReactComponent as DrawerButton } from "shared/assets/Icons/left.svg";
import { ReactComponent as HALogoFull } from "shared/assets/ha-full.svg";
import { ReactComponent as HALogoSmall } from "shared/assets/ha-small.svg";
import { NavigationItems } from "./NavigationItems";
import { UserProfile } from "../../UserProfile/UserProfile";
import { ReactComponent as WWLogoFull } from "shared/assets/wisdom-wellbeing-logo.svg";
import { ReactComponent as WWLogoSmall } from "shared/assets/wisdom-wellbeing-icon.svg";
import { isHealthAssuredLocale } from "shared/core/locale";
import { useGetAdminLocale } from "core/hooks/useGetAdminLocale";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const DesktopNavBar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const { t } = useTranslation();
  const locale = useGetAdminLocale(window.location.hostname);

  const LogoFull = isHealthAssuredLocale(locale) ? HALogoFull : WWLogoFull;
  const LogoSmall = isHealthAssuredLocale(locale) ? HALogoSmall : WWLogoSmall;

  return (
    <>
      <aside
        id="sidebar"
        className={`${isDrawerOpen ? "w-full md:w-3/12 h-1/6 md:h-full nav-expanded-max-w" : "w-1/12 nav-collapsed-max-w"}`}
        aria-label="Sidebar"
      >
        <div className="flex flex-col justify-between bg-white border-b-2 overflow-y-none md:h-screen md:border-r-2 md:border-b-0 border-drop-grey">
          <div className="flex flex-row justify-between px-4 py-2 mb-0">
            {isDrawerOpen ? <LogoFull className="w-40 h-10" /> : <LogoSmall className="h-10" />}
          </div>
          <ul role="navigation" aria-label="menu" className={`space-y-2 font-medium mb-auto`}>
            <NavigationItems isDrawerOpen={isDrawerOpen} />
          </ul>
          <UserProfile areDetailsVisible={isDrawerOpen} />
        </div>
      </aside>
      <div>
        <DrawerButton
          role="button"
          aria-label={isDrawerOpen ? t("navigation.ariaDrawerClose") : t("navigation.ariaDrawerOpen")}
          className={`mt-3 -ml-3 cursor-pointer ${isDrawerOpen ? "" : "transform -scale-x-100"}`}
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        />
      </div>
    </>
  );
};
