import helplineNumbersJson from "shared/assets/json/helplineNumbers.json";
import { removeSpaces } from "shared/core/utils/string";
import { Inputs } from "../ClientForm";
import { ClientCommand } from "shared/request/myHealthyAdvantageApi";

type HelplineNumber = { country: string; number: string };

export const helplineNumbers: HelplineNumber[] = helplineNumbersJson;

export const isHelplineNumberStringValid = (inputString: string): boolean => {
  let normalizedInput = removeSpaces(inputString);
  helplineNumbers.forEach(({ number }) => {
    const normalisedHelplineNumber = removeSpaces(number);
    if (normalizedInput.includes(normalisedHelplineNumber)) {
      normalizedInput = normalizedInput.replace(normalisedHelplineNumber, "");
    }
  });
  if (normalizedInput.replace(/[ /,]+/g, "") === "") return true;
  return false;
};

export const mapInputsToClientCommand = (inputs: Inputs): ClientCommand => {
  const { code, divisions, independentModules: others, support, wellbeing, name, phoneNumber, schemeNumber, localeId, userLimit } = inputs;

  const modules = others
    .concat(support, wellbeing)
    .filter((module) => module.selected)
    .map((item) => item.id);

  return {
    code,
    divisions,
    name,
    phoneNumber,
    schemeNumber,
    localeId,
    modules,
    userLimit: Number(userLimit),
  };
};
