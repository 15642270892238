import { ClientResponseModel, ClientCommand, EditClientCommand, useEditClientFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useLocation, useNavigate } from "react-router-dom";

import { ClientForm } from "../../Components/ClientForm/ClientForm";
import { getServerErrorMessage } from "shared/utils/serverError";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { useTranslation } from "react-i18next";

const viewClientsPath = "/clientmanagement";

export const EditClient = () => {
  const { t } = useTranslation();
  const navigateHook = useNavigate();
  const { state } = useLocation();
  let clientData: ClientResponseModel = state;
  const { trigger: editClientRequest } = useEditClientFunctionRequest({});

  const onSubmit = async (clientDetails: ClientCommand) => {
    const command: EditClientCommand = {
      ...clientDetails,
      id: clientData.id,
    };
    try {
      await editClientRequest({ body: command });
      toastNotify(t("clientForm.successToast", { clientName: clientDetails.name, action: "edited" }), "success");
      navigateHook(viewClientsPath);
    } catch (error: any) {
      toastNotify(getServerErrorMessage(error, t("clientForm.requestErrorMessage")), "error");
    }
  };

  return <ClientForm onSubmit={onSubmit} />;
};
