import { ReactComponent as HALogoFull } from "shared/assets/ha-full.svg";
import { ReactComponent as HamburgerButton } from "shared/assets/Icons/hamburger.svg";
import { NavigationItems } from "./NavigationItems";
import { UserProfile } from "../../UserProfile/UserProfile";
import { ReactComponent as WWLogoFull } from "shared/assets/wisdom-wellbeing-logo.svg";
import { isHealthAssuredLocale } from "shared/core/locale";
import { useGetAdminLocale } from "core/hooks/useGetAdminLocale";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const MobileNavBar = () => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const { t } = useTranslation();
  const locale = useGetAdminLocale(window.location.hostname);
  const LogoFull = isHealthAssuredLocale(locale) ? HALogoFull : WWLogoFull;
  return (
    <>
      <aside id="sidebar" className="w-full h-1/6" aria-label="Sidebar">
        <div
          className={`overflow-y-none flex flex-col ${
            isHamburgerOpen ? "h-screen" : ""
          }  justify-between bg-white border-b-2 md:border-r-2 border-drop-grey`}
        >
          <div className="flex flex-row justify-between px-4 py-1 mb-0">
            <LogoFull className="w-40 h-10" />
            <HamburgerButton
              role="button"
              aria-label={isHamburgerOpen ? t("navigation.ariaHamburgerClose") : t("navigation.ariaHamburgerOpen")}
              className="flex visible md:invisible"
              onClick={() => setIsHamburgerOpen(!isHamburgerOpen)}
            />
          </div>
          <ul role="navigation" aria-label="menu" className={`space-y-2 font-medium mb-auto ${isHamburgerOpen ? "" : "hidden"} md:visible md:block`}>
            <NavigationItems isDrawerOpen={true} />
          </ul>
          <div className={`${isHamburgerOpen ? "" : "hidden"} md:visible md:block`}>
            <UserProfile areDetailsVisible={true} />
          </div>
        </div>
      </aside>
    </>
  );
};
