import nzDefaultNamespaceTranslations from "./locales/nz/translations.json";
import auDefaultNamespaceTranslations from "./locales/au/translations.json";
import enDefaultNamespaceTranslations from "./locales/en/translations.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: enDefaultNamespaceTranslations,
      },
      en_AU: {
        translation: auDefaultNamespaceTranslations,
      },
      en_NZ: {
        translation: nzDefaultNamespaceTranslations,
      },
    },
    fallbackLng: "en",
    defaultNS: "translation",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
