import { ChangeEventHandler, useId, useMemo } from "react";

import { Select } from "../UI/Inputs/Select/Select";
import { useListLocaleFunctionRequest } from "../request/myHealthyAdvantageApi";

type LocaleSelectProps = {
  label?: string;
  onChange: ChangeEventHandler<HTMLSelectElement>;
  value?: string;
  ariaLabel: string;
  height?: string;
  width?: string;
  allOption?: boolean;
};

export const LocaleSelect = ({ label, onChange, value, ariaLabel, height, width, allOption }: LocaleSelectProps) => {
  const { data: localeList, isLoading, error } = useListLocaleFunctionRequest();
  const id = useId();
  const labelId = `select-${id}`;

  const selectValue = useMemo(() => {
    if (allOption) {
      return value === undefined ? "all" : value;
    }
    return value;
  }, [value, allOption]);

  return localeList && !isLoading && !error ? (
    <div className="relative w-full">
      <Select
        label={label ?? ariaLabel}
        id={labelId}
        value={selectValue}
        onChange={onChange}
        height={height ?? "h-[42px] font-semibold text-neutral-700"}
        width={width}
        aria-label={ariaLabel ?? label}
        data-testid="locale-selector"
      >
        <option hidden></option>
        {allOption && <option value={"all"}>All</option>}
        {localeList?.locales &&
          localeList.locales.map(({ id, description }) => (
            <option key={id} value={id}>
              {description}
            </option>
          ))}
      </Select>
    </div>
  ) : null;
};
