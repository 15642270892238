import { ClientCommand, useCreateClientFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { ClientForm } from "../../Components/ClientForm/ClientForm";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { getServerErrorMessage } from "shared/utils/serverError";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const viewClientsPath = "/clientmanagement";

export const CreateClient = () => {
  const { t } = useTranslation();
  const navigateHook = useNavigate();
  const { trigger: createClientRequest } = useCreateClientFunctionRequest({});

  const [createClientErrorMessage, setCreateClientErrorMessage] = useState<string | null>(null);

  const onSubmit = async (clientDetails: ClientCommand) => {
    setCreateClientErrorMessage("");
    try {
      await createClientRequest({ body: clientDetails });
      toastNotify(t("clientForm.successToast", { clientName: clientDetails.name, action: "created" }), "success");
      navigateHook(viewClientsPath);
    } catch (error: any) {
      setCreateClientErrorMessage(getServerErrorMessage(error, t("clientForm.requestErrorMessage", { clientFormAction: "creating" })));
    }
  };

  return (
    <>
      <ClientForm onSubmit={onSubmit} />
      {createClientErrorMessage && <ErrorAlert content={createClientErrorMessage} />}
    </>
  );
};
