import { ClientModuleResponseModel } from "shared/request/myHealthyAdvantageApi";
import { WisdomModules } from "shared/constants/Modules";
import { CheckboxGridItem } from "src/UIPalette/components/CheckboxGrid/CheckboxGrid";

export const dependencyKeys: Record<string, string[]> = {
  support: [WisdomModules.videoChat, WisdomModules.liveChat, WisdomModules.requestCallBack, WisdomModules.helpline],
  wellbeing: [WisdomModules.achievements, WisdomModules.leaderBoards],
};

export type AcceptableKeys = keyof typeof dependencyKeys;

export const getDependentModules = (key: AcceptableKeys, modulesList?: CheckboxGridItem[]) =>
  modulesList?.filter((module) => dependencyKeys[key].includes(module.key));

export const getIndependentModules = (modulesList: CheckboxGridItem[]) =>
  modulesList.filter((module) => {
    let dependencyCheck: boolean[] = [];
    Object.values(dependencyKeys).forEach((value) => {
      dependencyCheck.push(!value.includes(module.key));
    });
    return dependencyCheck.every((value) => value === true);
  });

export const checkDependencyStatus = (key: AcceptableKeys, modules?: ClientModuleResponseModel[]) =>
  modules?.some((clientModule) => dependencyKeys[key].includes(clientModule.key));
