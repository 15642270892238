import { CheckboxGrid, CheckboxGridItem } from "../../../../UIPalette/components/CheckboxGrid/CheckboxGrid";
import {
  ClientCommand,
  ClientResponseModel,
  DivisionModel,
  useListLocaleFunctionRequest,
  useListModulesFunctionRequest,
} from "shared/request/myHealthyAdvantageApi";
import { Controller, useForm } from "react-hook-form";
import { WisdomModules, clientTypes } from "shared/constants/Modules";
import { checkDependencyStatus, dependencyKeys, getDependentModules, getIndependentModules } from "../../View/clientModules";
import { isHelplineNumberStringValid, mapInputsToClientCommand } from "./utils/helpers";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ClientDivisionsEditor from "../ClientDivisionsEditor/ClientDivisionsEditor";
import { ClientLogo } from "shared/UI/ClientLogo/ClientLogo";
import { ConfirmationModal } from "shared/UI/Modal/ConfirmationModal/ConfirmationModal";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { Form } from "../../../../UIPalette/components/Form/Form";
import { Hideable } from "shared/UI/Hideable/Hideable";
import Input from "@brighthr/component-input";
import { LocaleSelect } from "shared/components/LocaleSelect";
import { PageHeader } from "../../../../UIPalette/components/PageHeader/PageHeader";
import ViewLoading from "shared/UI/Spinners/ViewLoading";
import { fromKebabToTitleCase } from "shared/core/utils/string";
import { useDefaultLocaleId } from "shared/core/hooks/useDefaultLocaleList";
import { useTranslation } from "react-i18next";

export type Inputs = {
  name: string;
  phoneNumber: string;
  code: string;
  schemeNumber: string;
  userLimit: number;
  localeId: string;
  support: CheckboxGridItem[];
  wellbeing: CheckboxGridItem[];
  independentModules: CheckboxGridItem[];
  divisions: DivisionModel[];
  clientType: CheckboxGridItem[];
};

const viewClientsPath = "/clientmanagement";

interface ClientFormProps {
  onSubmit: (clientData: ClientCommand) => void;
}

export const ClientForm = ({ onSubmit }: ClientFormProps) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigateHook = useNavigate();
  const defaultLocaleId = useDefaultLocaleId();
  const { data: moduleList, isLoading: isListModulesLoading } = useListModulesFunctionRequest();
  const { data: localeList, isLoading: isListLocaleLoading } = useListLocaleFunctionRequest();

  const clientData: ClientResponseModel = state;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalData, setModalData] = useState<Record<string, string>>();
  const [locale, setLocale] = useState(undefined);
  const [moduleUpdated, setModuleUpdated] = useState(false);
  const [isWellbeingDisabled, setIsWellbeingDisabled] = useState(false);
  const [isSupportDisabled, setIsSupportDisabled] = useState(false);

  const isClientEdit = !!clientData;
  const name = isClientEdit ? "editClient" : "createClient";
  const isStandardClient = checkDependencyStatus("support", clientData?.modules);
  const selectSupportModules = (key: string) => !!dependencyKeys.support.includes(key);

  const {
    control,
    getValues,
    reset,
    setValue,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm<Inputs>({
    defaultValues: isClientEdit
      ? {
        name: clientData.name,
        phoneNumber: clientData.phoneNumber,
        code: clientData.code,
        schemeNumber: clientData.schemeNumber,
        localeId: clientData.localeId,
        userLimit: clientData.userLimit,
        divisions: clientData.divisions ?? [],
        clientType: [],
        support: [],
        wellbeing: [],
        independentModules: [],
      }
      : {
        userLimit: 0,
        divisions: [],
        localeId: defaultLocaleId,
        clientType: [],
        support: [],
        wellbeing: [],
        independentModules: [],
      },
  });

  const handleStandardClientConfirmation = () => {
    setModalData({
      title: t("createClient.confirmation.modalTitle"),
      description: t("createClient.confirmation.modalMessage", { action: isClientEdit ? "save" : "create" }),
      confirmation: t("createClient.confirmation.modalConfirmTitle"),
      cancel: t("createClient.confirmation.modalDismissTitle"),
    });
    setShowConfirmationModal(true);
  };

  const handleModuleEditConfirmation = () => {
    setModalData({
      title: t("editClient.confirmation.modalTitle"),
      description: t("editClient.confirmation.modalMessage", { name: getValues("name") }),
      confirmation: t("editClient.confirmation.modalConfirmTitle"),
      cancel: t("editClient.confirmation.modalDismissTitle"),
    });
    setShowConfirmationModal(true);
  };

  const handleCreateClient = () => {
    const clientFormValues = getValues();
    if (!isSupportDisabled && !clientFormValues.support.some((item) => item.selected)) {
      handleStandardClientConfirmation();
    } else {
      handleSubmitForm();
    }
  };

  const handleLocaleSelect = (e: any, value: any, onChange: any) => {
    setLocale(value);
    return onChange(e);
  };

  const handleSubmitForm = () => {
    const clientFormValues = getValues();
    setShowConfirmationModal(false);
    setModalData(undefined);
    onSubmit(mapInputsToClientCommand(clientFormValues));
  };

  const onConfirmSubmit = () => {
    const clientFormValues = getValues();
    if (!isSupportDisabled && !clientFormValues.support.some((item) => item.selected)) {
      handleStandardClientConfirmation();
    } else if (dirtyFields.clientType || dirtyFields?.support || dirtyFields.wellbeing || dirtyFields.independentModules) {
      handleModuleEditConfirmation();
    } else {
      onSubmit(mapInputsToClientCommand(clientFormValues));
    }
  };

  const updateDependentModules = (key: "support" | "wellbeing", value: boolean) => {
    const clientFormValues = getValues();
    const newList = clientFormValues[key].map((item) => ({ ...item, selected: value }));
    setValue(key, newList, { shouldDirty: true });
  };

  const handleSupportChange = (state: CheckboxGridItem[]) => {
    setValue("support", state, { shouldDirty: true });
  };

  const handleClientTypeChange = (state: CheckboxGridItem[], onChange: (state: CheckboxGridItem[]) => void) => {
    onChange(state);
    const liteStatus = !!state?.find((type) => type.key === clientTypes.WISDOM_LITE)?.selected;
    updateDependentModules("support", !liteStatus);
    updateSupportDisabled();
  };

  const handleModulesChange = (state: CheckboxGridItem[], onChange: (state: CheckboxGridItem[]) => void) => {
    onChange(state);
    const wellBeingStatus = state?.find((type) => type.key === WisdomModules.trackers)?.selected;
    !wellBeingStatus && updateDependentModules("wellbeing", false);
    updateWellbeingDisabled();
  };

  const updateSupportDisabled = useCallback(() => setIsSupportDisabled(!!getValues("clientType").find((item) => item.key === clientTypes.WISDOM_LITE)?.selected), [setIsSupportDisabled, getValues]);
  const updateWellbeingDisabled = useCallback(() => setIsWellbeingDisabled(!getValues("independentModules").find((item) => item.key === WisdomModules.trackers)?.selected), [setIsWellbeingDisabled, getValues]);

  useEffect(() => {
    if (moduleList?.modules) {
      const modulesList = moduleList.modules.map((module) => ({
        id: module.id,
        key: module.key,
        displayText: t<string>(`clientModules.${module.key}`),
        selected: isClientEdit ? !!clientData.modules?.some((clientModule) => clientModule.id === module.id) : selectSupportModules(module.key),
      }));

      const clientTypeSelections = Object.values(clientTypes).map((type, index) => {
        const standardClientSelection: boolean = isClientEdit ? !!isStandardClient : true;
        return {
          id: index.toString(),
          key: type,
          displayText: fromKebabToTitleCase(type),
          selected: type === clientTypes.STANDARD_CLIENT ? standardClientSelection : !standardClientSelection,
        };
      });

      if (!moduleUpdated) {
        reset({
          support: getDependentModules("support", modulesList),
          wellbeing: getDependentModules("wellbeing", modulesList),
          independentModules: getIndependentModules(modulesList),
          clientType: clientTypeSelections,
        });
        setModuleUpdated(true);
      }
      updateSupportDisabled();
      updateWellbeingDisabled();
    }
  }, [moduleList, t, clientData?.modules, isClientEdit, isStandardClient, reset, moduleUpdated, updateWellbeingDisabled, updateSupportDisabled]);

  useEffect(() => {
    if (!isClientEdit) {
      if (!getValues("localeId")) {
        reset({
          ...getValues(),
          localeId: defaultLocaleId,
        });
      }
    }
  }, [defaultLocaleId, reset, getValues, isClientEdit, isStandardClient, clientData?.modules]);

  if (isListModulesLoading || isListLocaleLoading) {
    return <ViewLoading label={t("clientForm.loading")} />;
  }

  return (
    <>
      <PageHeader title={t(`${name}.title`)} subtitle={t(`${name}.subtitle`)} />
      {localeList && moduleList ? (
        <div>
          <Form
            className="mt-4"
            onSubmit={isClientEdit ? onConfirmSubmit : handleCreateClient}
            submitButtonText={t(`${name}.saveButton`)}
            onCancel={() => navigateHook(viewClientsPath)}
            validationTrigger={trigger}
          >
            <div className="content-center w-4/5 mt-4">
              <div className="flex flex-row justify-between mb-4">
                <div className="w-1/2">
                  <Controller
                    name={"name"}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Input
                          defaultValue={value}
                          onChange={onChange}
                          aria-label={t("clientForm.ariaClientName")}
                          placeholder={t("clientForm.clientNamePlaceholder")}
                        />
                        {errors.name && <ErrorAlert content={t("clientForm.clientNameErrorMessage")} />}
                      </>
                    )}
                  />
                </div>
                <div className="w-1/2 ml-4">
                  <Controller
                    name={"phoneNumber"}
                    control={control}
                    rules={{
                      required: true,
                      validate: {
                        isValid: isHelplineNumberStringValid,
                      },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Input
                          defaultValue={value}
                          onChange={onChange}
                          aria-label={t("clientForm.ariaPhoneNumber")}
                          placeholder={t("clientForm.phoneNumberPlaceholder")}
                        />
                        {errors.phoneNumber && <ErrorAlert content={t("clientForm.phoneNumberErrorMessage")} />}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-row justify-between mb-4">
                <div className="w-1/2">
                  <Controller
                    name={"code"}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Input
                          defaultValue={value}
                          onChange={onChange}
                          aria-label={t("clientForm.ariaCode")}
                          placeholder={t("clientForm.codePlaceholder")}
                        />
                        {errors.code && <ErrorAlert content={t("clientForm.codeErrorMessage")} />}
                      </>
                    )}
                  />
                </div>
                <div className="w-1/2 ml-4">
                  <Controller
                    name={"schemeNumber"}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Input
                        defaultValue={value}
                        onChange={onChange}
                        aria-label={t("clientForm.ariaSchemeNumber")}
                        placeholder={t("clientForm.schemeNumberPlaceholder")}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-row justify-between mb-4">
                <div className="w-1/2">
                  <Controller
                    name={"userLimit"}
                    control={control}
                    rules={{
                      required: true,
                      min: 1,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Input
                          defaultValue={value}
                          type="number"
                          onChange={onChange}
                          aria-label={t("clientForm.ariaUserLimit")}
                          placeholder={t("clientForm.userLimitPlaceholder")}
                        />
                        {errors.userLimit && <ErrorAlert content={t("clientForm.userLimitErrorMessage")} />}
                      </>
                    )}
                  />
                </div>
                <div className="w-1/2 ml-4">
                  <Controller
                    name={"localeId"}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <LocaleSelect
                        value={value}
                        onChange={(e) => handleLocaleSelect(e, value, onChange)}
                        height="h-[42px] font-semibold text-neutral-700"
                        ariaLabel={t("clientForm.ariaLocaleSelect")}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="mt-8 space-y-8">
                <Controller
                  control={control}
                  name="clientType"
                  render={({ field: { value, onChange } }) => {
                    return getValues("clientType")?.length === 0 ? (
                      <></>
                    ) : (
                      <CheckboxGrid
                        items={value}
                        headingText={t("clientForm.sections.clientType.heading")}
                        onChange={(state) => handleClientTypeChange(state, onChange)}
                        radioFields={Object.values(clientTypes)}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="support"
                  render={({ field: { value } }) => {
                    return getValues("support")?.length === 0 ? (
                      <></>
                    ) : (
                      <CheckboxGrid
                        items={value}
                        headingText={t("clientForm.sections.support.heading")}
                        onChange={(state) => handleSupportChange(state)}
                        disabled={isSupportDisabled}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="independentModules"
                  render={({ field: { value, onChange } }) => {
                    return getValues("independentModules")?.length === 0 ? (
                      <></>
                    ) : (
                      <CheckboxGrid
                        items={value}
                        headingText={t("clientForm.sections.independentModules.heading")}
                        onChange={(state) => handleModulesChange(state, onChange)}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="wellbeing"
                  render={({ field: { value, onChange } }) => {
                    return getValues("wellbeing")?.length === 0 ? (
                      <></>
                    ) : (
                      <CheckboxGrid
                        items={value}
                        headingText={t("clientForm.sections.wellbeing.heading")}
                        onChange={(state) => onChange(state)}
                        disabled={isWellbeingDisabled}
                      />
                    );
                  }}
                />
              </div>
              <div className="flex flex-row flex-grow">
                <div className="w-1/2">
                  <Controller
                    control={control}
                    name="divisions"
                    render={({ field: { value: divisions, onChange } }) => {
                      return (
                        <ClientDivisionsEditor
                          defaultLocale={getValues("localeId") || locale || clientData?.localeId}
                          initialDivisions={divisions}
                          onChange={(divisions) => onChange(divisions)}
                        />
                      );
                    }}
                  />
                </div>
                {clientData && (
                  <Hideable hidden={clientData.customLogo.length === 0}>
                    <div className="w-1/2 ml-4">
                      <h4 className="mt-8 mb-4">{t("editClient.logo.heading")}</h4>
                      <ClientLogo clientCode={clientData.code} imageFilename={clientData.customLogo} alt={t("editClient.logo.alt")} />
                    </div>
                  </Hideable>
                )}
              </div>
            </div>
          </Form>

          {showConfirmationModal && modalData && (
            <ConfirmationModal
              title={modalData.title}
              message={modalData.description}
              cancelButtonText={modalData.cancel}
              confirmButtonText={modalData.confirmation}
              onCancel={() => {
                setShowConfirmationModal(false);
              }}
              onConfirm={handleSubmitForm}
            />
          )}
        </div>
      ) : (
        <div className="w-full mt-4">
          <ErrorAlert content={t("clientForm.requestErrorBody")} />
        </div>
      )}
    </>
  );
};
